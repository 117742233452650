const evite = (window.evite = window.evite || {});

if (evite.debugging) {
  throw new Error(
    'Unexpected copy of evite.debugging module exported, you might have used an unusual import instead of the "evite" alias.'
  );
}

function consoleByMethodName(methodName, args) {
  const {console} = evite.global;

  if (process.env.LOGGING_ENABLED && console) {
    if (console[methodName]) {
      console[methodName].apply(console, args);
    } else if (methodName !== 'log') {
      consoleByMethodName('log', args);
    }
  }
}

export function log(...args) {
  consoleByMethodName('log', args);
}

export function debug(...args) {
  consoleByMethodName('debug', args);
}

export function info(...args) {
  consoleByMethodName('info', args);
}

export function error(...args) {
  consoleByMethodName('error', args);
}

export function trace(...args) {
  consoleByMethodName('trace', args);
}

export function warn(...args) {
  consoleByMethodName('warn', args);
}

export function assert(value, message) {
  if (value) {
    return value;
  }

  if (process.env.NODE_ENV === 'develop') {
    throw new Error(`Assertion Error: ${message || 'No message provide'}`);
  } else {
    consoleByMethodName('assert', [value, message || '']);
  }
}

function assertType(value, type) {
  const valueType = typeof value;
  const message = `Expected type (${type}), got (${valueType})`;
  assert(valueType === type, message);
  return value;
}

export function assertString(value) {
  return assertType(value, 'string');
}

export function assertNumber(value) {
  return assertType(value, 'number');
}

export function assertBoolean(value) {
  return assertType(value, 'boolean');
}

export function assertObject(value) {
  return assertType(value, 'object');
}

export function assertFunction(value) {
  const valueType = typeof value;
  const message = `Expected type (function), got (${valueType})`;
  assert(typeof value === 'function' || value instanceof Function, message);
  return value;
}

export function assertInstanceOf(value, instanceConstructor) {
  const message = `Expected value to be a instance of ${
    instanceConstructor.name || instanceConstructor
  }, got: ${value}`;
  assert(value instanceof instanceConstructor, message);
  return value;
}

/**
 * Execute a function and handle exceptions. This function should never throw an exception.
 *
 * @param func
 */
export function logAndContinue(func) {
  if (!func) return;

  try {
    return func();
  } catch (e) {
    logError(e);
  }
}

/**
 * Execute a function and handle exceptions. This function should never throw an exception.
 *
 * @param {string|Error} exception
 */
export function logError(exception) {
  if (exception) {
    if (window.Raven) {
      window.Raven.captureException(exception);
    }

    error(`Caught exception: ${exception}`);
    trace(exception);
  }
}

window.onunhandledrejection = (data) => {
  // too noisy:
  // window.Raven && Raven.captureException(data.reason);
  error('Unhandled promise rejection %O', data);
};
